import React from 'react';
import styled from 'styled-components';
import Navigation from '../components/ui/Navigation';
import { GlobalStyle } from '../styles/global-styles';
import { FontFamily } from '../styles/font-family';

const NavigationPageWrapper = styled.div``;

const NavigationPage = (): React.ReactElement => {
  return (
    <NavigationPageWrapper>
      <GlobalStyle />
      <FontFamily></FontFamily>
      <Navigation hasHomepageLink isPageMenu isMenuOpen></Navigation>
    </NavigationPageWrapper>
  );
};

export default NavigationPage;
