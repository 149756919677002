import { graphql } from 'gatsby';
import { PageReference, VersatileLink } from './ctaLink';

export const NavigationMenuFragment = graphql`
  fragment NavigationMenu on SanityWebsiteNestedEntryOrWebsiteSingleEntry {
    __typename
    ... on SanityWebsiteSingleEntry {
      title
      pageReference {
        ...PageReference
      }
      url
    }
    ... on SanityWebsiteNestedEntry {
      title
      pageReference {
        ...PageReference
      }
      entries {
        title
        description
        image {
          asset {
            url
          }
        }
        pageReference {
          ...PageReference
        }
        url
      }
      ctaLink {
        text
        url
        pageReference {
          ...PageReference
        }      
      }
    }
  }
`;

export type Entry = {
  title: string;
} & VersatileLink;

export interface NestedEntry {
  title: string;
  pageReference?: PageReference;
  entries: Array<Entry>;
  ctaLink?: Entry;
}

export type NavigationMenu = Array<Entry | NestedEntry>;

export function isNestedEntry(entry: Entry | NestedEntry): entry is NestedEntry {
  return 'entries' in entry;
}
