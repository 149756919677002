import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import media from 'css-in-js-media';
import Chevron from '../../images/UI/chevron-down.inline.svg';
import { Link } from 'gatsby';
import { getUrlFromVersatileLink } from '../../utils/utils';
import { NestedEntry as INestedEntry } from '../../graphql-fragments/navigationMenu';

import strip from '../../images/UI/menustrip.png';

interface NestedEntryProps {
  entry: INestedEntry;
  index?: number;
  startsOpen?: boolean;
  isPageMenu?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  isCondensed?: boolean;
  isMenuOpen?: boolean;
  setMenuData?: void;
  menuData?: void;
}

const NestedEntryContainer = styled.div`
`;

const MenuSubItemTitle = styled.button`
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: 0;

  color: ${({ isCondensed, isMenuOpen }) =>
    isCondensed || isMenuOpen ? 'var(--colour-black)' : 'var(--colour-white)'};
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: var(--light-body-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  letter-spacing: -0.2px;
  text-decoration: none;
  white-space: nowrap;

  svg {
    margin-left: 2px;
    transition: all 0.4s ease;
  }

  &:hover {
    color: var(--colour-turquoise);
    svg {
      path {
        stroke: var(--colour-turquoise);
      }
    }
  }

  .nestedEntryOpen & {
    color: var(--colour-turquoise);
    svg {
      transform: rotate(180deg);
      path {
        stroke: var(--colour-turquoise);
      }
    }
  }
`;

const SubTitle = styled.span`
  font-family: var(--light-title-font-family);
  font-size: 30px;
  font-weight: 300;
  line-height: 1.25em;
  color: white;
  display: flex;
  align-items: center;
  margin-top: 16px;

  ${media('>tablet')} {
    font-size: 36px;
  }
`;

const MenuSubItemGrid = styled.div`
  grid-template-columns: repeat(4, 1fr);
  gap: 80px;
  display: grid;
`;

const MenuSubItemGridBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  padding: 60px;
  position: absolute;
  left: 0px;
  top: 100%;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;

  .nestedEntryOpen & {
    opacity: 1;
    visibility: visible;
  }
`;

const MenuSubItemGridContainer = styled.div`
  background-color: var(--colour-black);
  padding: 60px;
  position: absolute;
  left: 0px;
  top: 100%;
  width: 100vw;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;

  .nestedEntryOpen & {
    opacity: 1;
    visibility: visible;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 21px;
    background-image: url(${strip});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 1;
  }
`;

const MenuSubItem = styled(Link)`
  color: var(--colour-white);
  leading-trim: both;
  text-edge: cap;
  font-family: var(--light-body-font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 100% */
  letter-spacing: -0.5px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 14px 0px;
  transition: all 0.4s ease;

  &.hasImage {
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.5px;
  }

  &:hover {
    & > p {
      color: var(--colour-white);
    }
  }
`;

const MenuSubItemDescription = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--light-body-font-family);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.2px;
  transition: all 0.4s ease;
  padding-right: 20px;
  max-width: 35ch;
`;

const MenuSubItemImage = styled.img`
  width: 30px;
  height: 30px;

  &.w-full {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const MenuCtaLink = styled(Link)`
  display: inline-flex;
  color: var(--colour-turquoise);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: var(--light-body-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  letter-spacing: -0.5px;
  text-decoration: none;
  margin-top: 31px;
  margin-bottom: 20px;
  align-items: center;
  gap: 0px 8px;
`;

const NestedEntry = ({
  entry,
  index,
  onClose,
  onOpen,
  startsOpen,
  isPageMenu,
  isCondensed,
  isMenuOpen,
  setMenuData,
  menuData,
}: NestedEntryProps) => {
  const isNestedEntryOpen = menuData[index].isOpen;

  const handleOpen = () => {
    setMenuData(prev => {
      return prev.map((entry, i) => {
        return {
          ...entry,
          isOpen: i === index ? !entry.isOpen : false,
        };
      });
    });
  };

  return (
    <NestedEntryContainer
      className={isNestedEntryOpen ? ' ' + 'nestedEntryOpen' : ''}
      onClick={handleOpen}
    >
      {isPageMenu ? (
        <SubTitle>{entry.title}</SubTitle>
      ) : (
        <MenuSubItemTitle
          aria-expanded={isNestedEntryOpen}
          aria-disabled="false"
          aria-controls={'panel_menu_' + index}
          tabIndex="0"
          role="button"
          isCondensed={isCondensed}
          isMenuOpen={isMenuOpen}
        >
          {entry.title}
          <Chevron />
        </MenuSubItemTitle>
      )}
      <MenuSubItemGridBackground
        className={isNestedEntryOpen ? 'nestedEntryOpen' : ''}
      />
      <MenuSubItemGridContainer
        id={'panel_menu_' + index}
        role="region"
        aria-hidden={!isNestedEntryOpen}
      >
        <MenuSubItemGrid>
          {entry.entries.map((entry, i) => (
            <MenuSubItem
              key={i}
              to={getUrlFromVersatileLink(entry)}
              className={entry.image && !/\.(svg)$/.test(entry.image?.asset?.url) ? 'hasImage' : ''}
            >
              {entry.image && (
                <MenuSubItemImage
                  src={entry.image.asset?.url}
                  alt={entry.image.alt}
                  className={/\.(svg)$/.test(entry.image?.asset?.url) ? '' : 'w-full'}
                />
              )}
              {entry.title}
              {(!entry.image || /\.(svg)$/.test(entry.image?.asset?.url)) && (
                <MenuSubItemDescription>{entry.description}</MenuSubItemDescription>
              )}
            </MenuSubItem>
          ))}
        </MenuSubItemGrid>
        {entry.ctaLink && (
          <MenuCtaLink to={getUrlFromVersatileLink(entry.ctaLink)} className="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" fill="none">
              <path d="M5 1.5L11 7.31818L5 13.5" stroke="currentColor" stroke-width="1.5"/>
              <path d="M0 7.5H11" stroke="#1ACEBC" stroke-width="1.5"/>
            </svg>            
            {entry.ctaLink.text}
          </MenuCtaLink>
        )}
      </MenuSubItemGridContainer>
    </NestedEntryContainer>
  );
};

export default NestedEntry;
