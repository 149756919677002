import { INSIGHTS_URL } from '../constants';
import { PageReference, PageReferenceType, VersatileLink } from '../graphql-fragments/ctaLink';
import { ModuleBgColor } from '../graphql-fragments/modules';
import { isNestedEntry, NavigationMenu } from '../graphql-fragments/navigationMenu';

type ParentMenuEntry = { title: string; url: string } | null;
/**
 * Searches if pageUrl is a subEntry in the navigationMenu and returns the
 * parent information, otherwise returns null
 */
export function getParentMenuEntry(
  pageUrl: string,
  navigationMenu: NavigationMenu,
): ParentMenuEntry {
  let parentMenuEntry: ParentMenuEntry = null;

  for (const entry of navigationMenu) {
    if (
      isNestedEntry(entry) &&
      entry.pageReference &&
      entry.entries.find(
        subEntry =>
          subEntry.pageReference && getPageReferenceUrl(subEntry.pageReference) === pageUrl,
      )
    ) {
      parentMenuEntry = {
        title: entry.title,
        url: getPageReferenceUrl(entry.pageReference),
      };
    }
  }
  return parentMenuEntry;
}

/**
 * Tests if two ModuleBgColor are the same and are both solid
 */
export function areSameFlatColor<ColorName1 extends string, ColorName2 extends string>(
  color1: ModuleBgColor<ColorName1> | null,
  color2: ModuleBgColor<ColorName2> | null,
): boolean {
  if ((color1 && color1.type === 'unknown') || (color2 && color2.type === 'unknown')) {
    console.warn(
      'Found color with type unknown: ' + JSON.stringify(color1) + ' ' + JSON.stringify(color2),
    );
  }
  return (
    !!color1 &&
    !!color2 &&
    color1.type === 'solid' &&
    color2.type === 'solid' &&
    // @ts-ignore
    color1.name === color2.name
  );
}

/**
 * Returns a url depending on whether the versatileLink has a pageReference or a url.
 * If it has a pageReference, returns a url path with the pageReference's slug and
 * the right prefix depending on the pageReferences's __typename.
 * If it has a url, returns it.
 */
export function getUrlFromVersatileLink(versatileLink: VersatileLink): string {
  if (!versatileLink.pageReference && !versatileLink.url) {
    throw new Error(`Please add an url or a page reference`);
  }
  return versatileLink.pageReference
    ? getPageReferenceUrl(versatileLink.pageReference)
    : versatileLink.url;
}

/**
 * Returns a url path with the pageReference's slug and the right prefix
 * depending on the pageReferences's __typename
 */
export function getPageReferenceUrl(pageReference: PageReference): string {
  return getReferenceUrl(pageReference.__typename, pageReference.slug.current);
}

/**
 * Returns a url path with the slug and the right prefix depending on the typeName
 */
export function getReferenceUrl(typeName: PageReferenceType, slug: string): string {
  const prefixSlash = slug.startsWith('/') ? '' : '/';
  switch (typeName) {
    case 'SanityPage':
      return prefixSlash + slug;
    case 'SanityInsight':
      return INSIGHTS_URL + prefixSlash + slug;
    case 'SanitySpotlight':
      return '/spotlights' + prefixSlash + slug;
    case 'SanityService':
      return '/services' + prefixSlash + slug;
    case 'SanitySector':
      return '/sectors' + prefixSlash + slug;
    default:
      assertNever(typeName);
  }
}

/**
 * Takes any number of arguments, filters "false" ones (false, null, undefined) and joins the rest with spaces.
 * Use this to easily add multiple classes together, where some of them should be added conditionally.
 *
 * Example: clsx('someClass', condition && otherClass, potentiallyUndefinedClass)
 *
 * Function inspired by https://github.com/lukeed/clsx
 */
export const clsx = (...args: Array<string | false | null | undefined>): string =>
  args.filter(Boolean).join(' ');

export function isDeviceMobile(): boolean {
  if (typeof window === 'undefined') {
    return false;
  }

  const mobileRegex = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/;
  return (
    mobileRegex.test(window.navigator.appVersion) || mobileRegex.test(window.navigator.userAgent)
  );
}

export function slugify(str: string): string {
  return str.replace(/\s/gi, '-').toLowerCase();
}

/**
 * Truncates a string depending on the maxLength and replace excess text with '...'
 */
export function truncateText(str: string, maxLength: number): string {
  if (str.length <= maxLength) {
    return str;
  }
  const strParts = str.match(/(^|[^A-zÀ-ú\-])+[A-zÀ-ú\-]+/g) || [];
  let truncateStr = '';
  for (const strPart of strParts) {
    if (truncateStr.length + strPart.length + 3 > maxLength) {
      break;
    }
    truncateStr += strPart;
  }
  return truncateStr + '...';
}

export function assertNever(value: never, noThrow?: boolean): never {
  if (noThrow) {
    return value;
  }

  throw new Error(`Unexpected code reached with value: ${value}`);
}
