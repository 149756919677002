import media from 'css-in-js-media';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getUrlFromVersatileLink } from '../../utils/utils';

import { FooterProps } from '../../components/ui/Footer';
import { isNestedEntry, NavigationMenu } from '../../graphql-fragments/navigationMenu';

import NestedEntry from './NestedEntry';

interface QueryData {
  sanityWebsite: {
    navigationMenu: NavigationMenu;
    footer: FooterProps;
  };
}

interface NavigationProps {
  hasHomepageLink?: boolean;
  isPageMenu?: boolean;
  isMenuOpen?: boolean;
  onLogoClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  isCondensed?: boolean;
  setIsMenuOpen?: void;
}

const MenuNav = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0px 26px;
  margin-left: auto;
  justify-content: flex-end;

  & > a:last-child {
    border-radius: 6px;
    background: var(--colour-turquoise);
    color: var(--colour-black);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: var(--light-body-font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 100% */
    letter-spacing: -0.5px;
    padding: 14px;
  }

  ${media('<=desktop')} {
    display: none;
  }
`;

const MenuItem = styled(Link)`
  color: ${({ isCondensed, isMenuOpen }) => (isCondensed || isMenuOpen ? 'var(--colour-black)' : 'var(--colour-white)')};
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: var(--light-body-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  letter-spacing: -0.2px;
  text-decoration: none;
  white-space: nowrap;
`;

const Navigation = ({ isPageMenu, isCondensed, isMenuOpen, setIsMenuOpen }: NavigationProps): React.ReactElement => {
  const data: QueryData = useStaticQuery(
    graphql`
      {
        sanityWebsite {
          navigationMenu {
            ...NavigationMenu
          }
          footer {
            socialMedia {
              facebookUrl
              twitterUrl
              linkedinUrl
            }
          }
        }
      }
    `,
  );

  const menu = data.sanityWebsite.navigationMenu;
  const socialMedia = data.sanityWebsite.footer.socialMedia;

  const [menuData, setMenuData] = useState(
    menu.map((entry) => {
      return {
        ...entry,
        isOpen: false,
      };
    }),
  );

  useEffect(() => {
    setIsMenuOpen(false); 
    menuData.forEach((entry) => {
      if (entry.isOpen) {
        setIsMenuOpen(true);
      }
    });
  }, [menuData]);

  return (
    <MenuNav>
      {menuData.map((menuEntry, i) =>
        isNestedEntry(menuEntry) ? (
          <NestedEntry entry={menuEntry} index={i} isPageMenu={isPageMenu} isCondensed={isCondensed} isMenuOpen={isMenuOpen} setMenuData={setMenuData} menuData={menuData}></NestedEntry>
        ) : (
          <MenuItem key={i} to={getUrlFromVersatileLink(menuEntry)} isCondensed={isCondensed} isMenuOpen={isMenuOpen}>
            {menuEntry.title}
          </MenuItem>
        ),
      )}

    </MenuNav>
  );
};

export default Navigation;
