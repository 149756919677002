import { createGlobalStyle } from 'styled-components';

import alliance1LightWoff from '../fonts/alliance1-light.woff';
import alliance1LightWoff2 from '../fonts/alliance1-light.woff2';
import alliance1MediumWoff from '../fonts/alliance1-medium.woff';
import alliance1MediumWoff2 from '../fonts/alliance1-medium.woff2';

import alliance2LightWoff from '../fonts/alliance2-light.woff';
import alliance2LightWoff2 from '../fonts/alliance2-light.woff2';

export const FontFamily = createGlobalStyle`
  @font-face {
    font-family: "Alliance 1";
    src: url("${alliance1LightWoff}") format("woff"), url("${alliance1LightWoff2}") format("woff2");
    font-display: swap;
    font-weight: 300;
  }
  @font-face {
    font-family: "Alliance 1";
    src: url("${alliance1MediumWoff}") format("woff"), url("${alliance1MediumWoff2}") format("woff2");
    font-display: swap;
    font-weight: 500;
  }
  @font-face {
    font-family: "Alliance 2";
    src: url("${alliance2LightWoff}") format("woff"), url("${alliance2LightWoff2}") format("woff2");
    font-display: swap;
    font-weight: 300;
  }
`;
