export const DEFAULT_LANG = 'en';

export const DEFAULT_SITE_DOMAIN = 'axiologik.com';
export const DEFAULT_SITE_BASE_URL = 'https://axiologik.com/';

export const SITE_DOMAIN =
  process.env.NODE_ENV === 'development'
    ? 'localhost:8000'
    : process.env.GATSBY_SITE_DOMAIN || DEFAULT_SITE_DOMAIN;

export const SITE_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : process.env.GATSBY_SITE_BASE_URL || DEFAULT_SITE_BASE_URL;

export const MAX_OG_DESCRIPTION_LENGTH = 300;

export const INSIGHTS_URL = '/news-and-insights';
